.about {
     background-image: linear-gradient(
         to left,
         rgba(6, 2, 31, 0.88),
         rgba(9, 9, 11, 0.765)
       ),
       url("../../assets/about-bg.png");
     /* background-repeat: no-repeat;
         background-size: cover; */
     height: auto;
     width: 100%;
     /* background-size: contain; */
     background-size: cover;
   
     background-size: 100% 85%;
   
     background-repeat: no-repeat;
   }